































































































































































































































.notes_scroll{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 8px;
  margin: 0 -8px;
}
.notes-list_scroll{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 14px;
  margin: 0 -14px;
}
